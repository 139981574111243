import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { OrderLoginRegister } from "page_components/login-register"

import { isUserLoggedIn } from "utils/functions"

const LoginPage = ({ location }) => {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }
  }, [loggedIn])

  return (
    <Layout location={location}>
      <Breadcrumbs title="Konto" />
      <OrderLoginRegister loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    </Layout>
  )
}

export const Head = () => <Seo title="Konto" />

export default LoginPage
